* {
  --transition-duration: 150ms;
}

html, body {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #BABABA;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  cursor: default;
}

header {
  border-bottom: 1px solid #BABABA;
  margin: 1rem 0;
}

nav {
  display: flex;
  gap: 1rem;
}

header a, nav a {
  color: unset;
  text-decoration: none;
  font-weight: bold;
  transition-duration: var(--transition-duration);
}

nav a:nth-child(n+1):hover {
  color: #F0F;
}

nav a:nth-child(n+2):hover {
  color: #0FF;
}

nav a:nth-child(n+3):hover {
  color: #FF0;
}

.App {
  width: 60%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

#resume a {
  float: right;
  color: unset;
  text-decoration: none;
  transition-duration: var(--transition-duration);
}

#resume a:hover {
  color: #FFF;
}
